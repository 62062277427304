<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('exam_schedule1')"
                        :isNewButton="checkPermission('examschedule_store')"
                        :isColumns="true"
                        :isImportButton="checkPermission('examschedule_import')"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus = $event"
                        @import-button-click="importFormShow"
                        :otherButton="studentPlacementObj"
                        @studentPlacement="studentPlacementModal"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('exam_schedule1')"
                              :isNewButton="checkPermission('examschedule_store')"
                              :isColumns="true"
                              :isImportButton="checkPermission('examschedule_import')"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus = $event"
                              @import-button-click="importFormShow"
                              :otherButton="studentPlacementObj"
                              studentPlacement="studentPlacementModal"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox
                                :id="'checkbox-' + key"
                                :name="'checkbox-' + key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Datatable -->
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="checkPermission('examschedule_excelexport')"
                @exportExcel="downloadExcel"
                :student-list-report="true"
                @getStudentListReport="getStudentListReport"
                >
                <b-row>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year"/>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="3" xl="3">
                        <b-form-group :label="$t('semester')">
                            <parameter-selectbox
                                code="semester"
                                v-model="datatable.queryParams.filter.semester"
                            ></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('exam_type')">
                            <parameter-selectbox code="exam_schedules_types"
                                                 v-model="datatable.queryParams.filter.type"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('teaching_staff')">
                            <staff-auto-complete v-model="datatable.queryParams.filter.instructor_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete v-model="datatable.queryParams.filter.course_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('exam_date')">
                            <b-form-input type="date" v-model="datatable.queryParams.filter.date"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('start_time')">
                            <b-form-input type="time" v-model="datatable.queryParams.filter.start_time"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('end_time')">
                            <b-form-input type="time" v-model="datatable.queryParams.filter.end_time"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('campus')">
                            <campus-selectbox v-model="campus_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('building')">
                            <building-selectbox :campus_id="campus_id" v-model="building_id"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('classroom')">
                            <classroom-selectbox :building_id="building_id"
                                                 v-model="datatable.queryParams.filter.classroom"/>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="3">
                        <b-form-group :label="$t('observer')">
                            <StaffAutoComplete
                                v-model="datatable.queryParams.filter.observers"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <!-- Create Form -->
            <CommonModal ref="createFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new') }}
                </template>
                <template v-slot:CommonModalContent>
                    <CreateForm
                        @createFormSuccess="createFormSuccess"
                                v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>

            <!-- Update Form-->
            <CommonModal ref="updateFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{changeText($t('edit')) }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateForm
                        :formId="formId"
                        @updateFormSuccess="updateFormSuccess"
                        v-if="formProcess=='update'"/>
                </template>
            </CommonModal>

            <!-- Observers-->
            <CommonModal ref="observerFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('observers') }}
                </template>
                <template v-slot:CommonModalContent>
                    <Observer :formId="formId"
                              @observerFormSuccess="observerFormSuccess"
                              v-if="formProcess=='observer'"/>
                </template>
            </CommonModal>

            <!-- Import Form-->
            <CommonModal ref="importFormModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit') }}
                </template>
                <template v-slot:CommonModalContent>
                    <importForm @importFormSuccess="importFormSuccess"
                                v-if="formProcess=='import'"/>
                </template>
            </CommonModal>

            <!-- Student Placement Form-->
            <CommonModal ref="studentPlacement" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('placement_student') }}
                </template>
                <template v-slot:CommonModalContent >
                    <placement-student v-if="formProcess=='studentPlacement'" @formSuccess="formSuccess"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import BuildingSelectbox from "@/components/interactive-fields/BuildingSelectbox";
import CampusSelectbox from "@/components/interactive-fields/CampusSelectbox";
import ClassroomSelectbox from "@/components/interactive-fields/ClassroomSelectbox";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";



// Pages
import CreateForm from "./CreateForm";
import UpdateForm from "./UpdateForm";
import Observer from "./Observer";
import importForm from "./importForm";
import PlacementStudent from "./PlacementStudent.vue";

// Services
import ExamScheduleService from "@/services/ExamScheduleService";

// Others
import qs from 'qs'
import i18n from "@/plugins/i18n";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        AcademicYearsSelectbox,
        BuildingSelectbox,
        CampusSelectbox,
        ClassroomSelectbox,
        CourseAutoComplete,
        FacultySelectbox,
        ParameterSelectbox,
        StaffAutoComplete,

        CreateForm,
        UpdateForm,
        Observer,
        importForm,
        PlacementStudent

    },
    metaInfo() {
        return {
            title: this.$t('exam_schedule1')
        }
    },
    data() {
        return {
            studentPlacementObj: {
                show: false,
                emit: 'studentPlacement',
                title: i18n.t('placement_student'),
                icon: 'ri-todo-line'
            },
            classrooms: [],
            campus_id: null,
            building_id: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("observers"),
                                class: "ri-user-2-fill align-middle top-minus-1 mr-3 text-muted",
                                callback: (row) => {
                                    this.observerFormShow(row.id);
                                },
                                permission: 'examschedule_updateobservers'
                            },
                            {
                                text: this.$t("edit"),
                                class: "ri-search-eye-line align-middle top-minus-1 mr-3 text-muted",
                                callback: (row) => {
                                    this.updateFormShow(row.id);
                                },
                                permission: 'examschedule_update'
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted",
                                callback: (row) => {
                                    this.formDelete(row.id);
                                },
                                permission: 'examschedule_delete'
                            },
                        ],
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        hidden: true,
                    },
                    {
                        label: this.toUpperCase("date") + ', ' + this.toUpperCase("hour"),
                        field: "date",
                        hidden: false,
                        formatFn: (value, row) => {
                            return this.toLocaleDate(value, 'LL') + ', ' + row.start_time + '-' + row.end_time;
                        },
                    },
                    {
                        label: this.toUpperCase("course"),
                        field: "course_name",
                        hidden: false,
                        sortable: false,
                        formatFn: (value, row) => {
                            return row.course_code + ' - ' + value;
                        }
                    },
                    {
                        label: this.$t('sections'),
                        field: "section",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("exam_type"),
                        field: "type",
                        hidden: false,
                        sortable: false,
                        formatFn: (value, row) => {
                            return row.exam_name
                        }
                    },
                    {
                        label: this.toUpperCase("classroom"),
                        field: "classrooms",
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            let data = [];
                            value.map((item) => {
                                data.push(item);
                            });
                            return data.join(", ");
                        },
                    },
                    {
                        label: this.toUpperCase("teaching_staff"),
                        field: "instructor",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("observers"),
                        field: "observers",
                        hidden: false,
                        sortable: false,
                        formatFn: (value) => {
                            let data = [];
                            value.map((item) => {
                                if (data.indexOf(item.full_name) == -1) {
                                    data.push(item.full_name)
                                }
                            });
                            return data.join(", ");
                        },
                    },
                    {
                        label: this.toUpperCase("allow_classroom_overlap"),
                        field: "allow_classroom_overlap",
                        hidden: true,
                        sortable: false,
                        formatFn: (value) => {
                            return value == true ? this.$t('yes') : this.$t('no');
                        },
                    },
                    {
                        label: this.toUpperCase("allow_instructor_overlap"),
                        field: "allow_instructor_overlap",
                        hidden: true,
                        sortable: false,
                        formatFn: (value) => {
                            return value == true ? this.$t('yes') : this.$t('no');
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-date",
                    page: 1,
                    limit: 20,
                },
            },
            formProcess: null,
            formId: null,
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                academic_year: null,
                type: null,
                faculty_code: null,
                instructor_id: null,
                course_id: null,
                date: null,
                start_time: null,
                end_time: null,
                classroom: null,
                semester:null,
                observers:null
            }
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            return ExamScheduleService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                    this.datatable.rows.forEach((c) => c.status = c.status == 'a' ? this.$t('active') : this.$t('passive'))
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        // Clear
        formClear() {
            this.formId = null
            this.formProcess = null
        },

        // Create
        createFormShow() {
            this.formId = null
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Update
        updateFormShow(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.updateFormModal.$refs.commonModal.show()
        },
        updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Observer
        observerFormShow(id) {
            this.formId = id
            this.formProcess = 'observer'
            this.$refs.observerFormModal.$refs.commonModal.show()
        },
        observerFormSuccess() {
            this.$refs.observerFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Import
        importFormShow(id) {
            this.formId = id
            this.formProcess = 'import'
            this.$refs.importFormModal.$refs.commonModal.show()
        },
        importFormSuccess() {
            this.$refs.importFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },

        // Delete
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        ExamScheduleService.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            });
                    }
                })
        },
        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            ExamScheduleService.downloadAsExcel(config)
                .then(res => this._downloadFile(res, this.$t('exam_schedule') + '.xlsx'))
                .catch(err => this.showErrors(err))

        },
        studentPlacementModal(){
            this.formProcess='studentPlacement';
            this.$refs.studentPlacement.$refs.commonModal.show();
        },
        formSuccess(){
            this.$refs.studentPlacement.$refs.commonModal.hide();
            this.formProcess = null;
        },
        getStudentListReport() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            ExamScheduleService.getStudentListReport(config)
                .then((response) => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                })
                .catch((error) => {
                    this.showErrors(error)
                })
        }

    },

    mounted(){
        this.studentPlacementObj.show = this.checkPermission('examschedule_studentclassroomplacement')
    }
}
</script>

